import * as React from 'react'
// material
import { Container } from '@mui/material';
// components
import HomePage from '../components/heroes/HomePage';
import TitlePageBlock from '../components/heroes/TitlePageBlock';
import TextPageBlock from '../components/heroes/TextPageBlock';

// ----------------------------------------------------------------------

export default function FAQs() {
  return (
    <HomePage title="FAQs">
      <TitlePageBlock title="FAQs" subTitle="TODO: FAQs to come here" imgUrl="https://uxwing.com/wp-content/themes/uxwing/download/42-business-professional-services/company-enterprise.png"/>
    </HomePage>
  );
}